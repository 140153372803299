.trip-details {
  position: relative;
  .connected-vehicle-info .row {
    height: 40px;
    overflow: visible;

    .save-control {
      position: absolute;
      top: 0;
      right: 24px;
      padding: 0;
    }

    .field {
      margin: 0;
      width: 25%;
    }
  }
}
