input,
textarea,
select,
button {
  font-family: inherit;
}

h2, .h2 {
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
}

h3, .h3 {
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0 16px;
  font-weight: bold;
}

h4, .h4 {
  margin: 0 0 16px;
  font-size: 14px;
  font-weight: bold;
}

h5 {
  font-size: 12px;
  margin: 0 0 16px;
}
.active {
  color: var(--primary);
}

.bold {
  font-weight: 700;
}

.link, .external-link {
  color: var(--primary);
  font-weight: normal;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: var(--disabled-gray);
  }

  &:hover {
    color: var(--primary-hover);

    .icon {
      filter: var(--filter-primary-hover)
    }
  }

  &.underline {
    display: inline-block;
    border-bottom: 1px solid var(--primary);

    &.disabled {
      border-bottom-color: var(--disabled-gray);
    }

    &:hover {
      border-bottom-color: var(--primary-hover);
    }
  }
}

.hidden {
  visibility: hidden;
}

.d {
  &-none {
    display: none;
  }

  &-block {
    display: block;
  }
}

.ReactModalPortal .modal-overlay {
  z-index: 5;
}

a.button {
  text-decoration: none;
}
