.composition {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  &-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border: 1px solid var(--border);
    max-height: calc(100vh - 136px);
    height: 100%;
    overflow: hidden;
  }

  &-viewer {
    background: white;
    flex-grow: 1;
    min-width: 600px;
    text-align: center;
    position: relative;

    .composition-viewer-container {
      height: 100%;
    }

    .fleet-viewer {
      min-height: 500px;
      flex: 1;

      .composition-wrapper {
        display: flex;

        .empty-composition-message {
          width: 290px;
          position: absolute;
          left: 50%;
          top: 160px;
          transform: translateX(-50%);

          p {
            color: var(--disabled-gray);
            font-size: 16px;
          }
        }

        .left-padding, .right-padding {
          flex-basis: 20%;
          flex-grow: 2;
        }
      }
    }
    &-container {
      display: flex;
    }
  }

  &-vehicles {
    position: sticky;
    top: 0;
    padding: 24px 12px 24px 0;
    width: 90px;
    height: 100%;

    &:empty {
      display: none;
    }
  }

  .composition-properties-panel {
    padding: 0 20px 20px;
    width: 320px;
    background: var(--bg);

    .carriage-properties {
      .field:nth-of-type(4) {
        order: 10;

        .field-label {
          font-weight: bold;
        }

        + .field {
          order: 11;
        }
      }
    }
  }
}
