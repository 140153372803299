.composition-manage {
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid var(--border);
  display: flex;
  flex: 1;
  min-height: 0;

  .palette-panel, .fleet-viewer, .properties-edit-panel {
    > h2 {
      padding: 24px 0 16px;
      margin: 0;
    }
  }

  .properties-edit-panel {
    min-height: 500px;
  }

  .composition-viewer {
    .zoom-controls {
      visibility: hidden;
    }

    .compositions-control {
      width: 33%;
    }

    &.is-dragging {
      .composition-viewer-container {
        .fleet-viewer {
          overflow: hidden;
        }

        .canvas {
          height: 0;
          overflow: hidden;
        }
      }
    }
  }


  &.trip-composition  .icons {
    button:nth-child(2) {
      display: none;
    }
  }

  .composition-vehicles {
    height: auto;
  }

  .composition-tabs {
    position: relative;
    width: 120px;
    margin: auto;
    &-direction {
      position: absolute;
      width: 9px;
      height: 118px;
      top: 50%;
      left: 22px;
      margin-top: -59px;
    }
  }
}
