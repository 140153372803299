.relations-search-wrapper {
  flex: 1;
  display: flex;
  padding: 0;
  min-height: 0;
  flex-direction: column;
  .relations-search {
    flex: 1;
    display: flex;
    padding: 0;
    min-height: 0;
    flex-direction: column;
    .relations-title {
      margin-bottom: 24px;
    }

    .relations-filter {
      padding: 0 24px 32px;
      border-bottom: 1px solid var(--border);

      > .filter-row {
        display: flex;
        align-items: flex-end;

        &:not(:first-of-type) {
          padding-top: 12px;
        }
      }

      .datepicker-wrapper {
        padding-right: 32px;
        margin-bottom: 0;
        max-width: 17%;
      }

      .departure-days-filter {
        label {
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .datepicker-wrapper.range {
        max-width: 25%;

        .MuiBox-root {
          margin: 0;
        }
      }

      .filter-btn {
        align-self: flex-start;
        margin-top: auto;
        width: 179px;
        max-width: 15%;

        & ~ * {
          margin-top: 16px;
        }
      }

      .departure-days-filter {
        padding-right: 24px;
        display: flex;
        flex-direction: column;

        .toggler-option {
          flex-grow: 1;
          padding: 5px 11px;
        }
      }

      .departure-days-filter,
      .composition-relation-filter {
        > label {
          font-size: 12px;
          display: inline-block;
          margin-bottom: 8px;
        }
      }

      .checkbox {
        margin-bottom: 8px;
      }

      .radio-group {
        display: flex;

        .radio {
          margin-bottom: 6px;
          margin-right: 20px;
        }

        & + .checkbox {
          margin-bottom: 4px;
        }
      }
    }

    .empty-search-result {
      padding: 32px 0;
      text-align: center;

      img {
        display: block;
        margin: 0 auto 32px;
      }

      span {
        font-size: 16px;
        color: var(--dark-gray);
      }
    }
  }

  .composition-relations,
  .relations-search {
    .heading {
      display: flex;
      padding: 0 24px;
      height: 56px;
      align-items: center;

      .heading-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-right: 16px;
      }

      .connected-number {
        font-size: 12px;
        color: var(--mid-gray);
        margin-right: 32px;
      }

      .connected-trip-status-filter {
        font-size: 12px;

        > span {
          margin-right: 14px;
        }
        > .radio-group {
          display: inline-flex;

          .radio {
            margin-bottom: 0;
            margin-right: 24px;

            .option-label {
              padding-left: 32px;

              &:before {
                transform: translateY(-50%) scale(0.85);
              }
            }
          }
        }
      }
    }
  }

  .composition-relations {
    > h2 {
      border-bottom: 1px solid var(--border);
    }

    .assigned-none-message {
      padding: 0 24px 24px;
    }
  }

  .relations-filter {
    .field {
      padding-right: 32px;
      margin-bottom: 0;
      max-width: 17%;
      line-height: 20px;

      .field-label {
        margin-bottom: 8px;
        font-size: 12px;
        color: var(--text);
      }

      .toggler-option {
        font-size: 14px;
      }

      .radio-group {
        height: 32px;

        .radio {
          margin-top: 6px;
        }
      }
    }

    .datepicker-wrapper {
      max-width: none;

      .datepicker-input-wrapper {
        width: auto;
      }
    }
  }

  .composition-relations-table {
    .field-label {
      margin-bottom: 8px;
      color: var(--mid-gray);
    }
    th,
    td {
      font-size: 12px;
      line-height: 16px;
    }
    thead {
      th {
        background: var(--bg);
      }
    }

    tbody {
      .line-template-name-col {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        //color: var(--primary);
      }

      tr {
        height: 36px;
        &.expanded-row-info {
          cursor: default;
        }
        td {
          white-space: nowrap;
          padding: 0;
          max-width: 14vw;
          overflow: hidden;
          &:not(.row-controls) {
            text-overflow: ellipsis;
          }
        }
      }

      .row-controls {
        text-align: right;
      }
    }
  }
}
