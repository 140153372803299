.line-template-timetable {
  display: flex;
  font-size: 12px;
  text-align: center;
  background: white;
  width: 100%;


  .column {
    width: 100%;
    border: 1px solid var(--border);

    &:not(:last-of-type) {
      border-right: none;
    }

    .column-header {
      background: var(--bg);
      padding: 6px 0 5px;
      color: var(--mid-gray);
      border-bottom: 1px solid var(--border);
    }

    .time {
      padding: 2px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--border);
      }

      i.tick {
        display: none;
      }

      &:not(.editable) {
        color: white;

        &.selected {
          color: var(--text);
        }
      }

      &.editable {
        cursor: pointer;

        &.selected {
          color: white;
          background: var(--secondary);
          i.tick {
            display: inline-block;
            vertical-align: text-top;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
