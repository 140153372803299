.properties-edit-panel {
  .place-properties-edit {
    position: relative;
    display: flex;
    flex-direction: column;

    .seat-filter.visible {
      &:after {
        content: '';
        display: block;
        margin: 16px -24px 0 -24px;
        border-top: 1px solid var(--border);
      }
    }

    .bulk-edit {
      position: absolute;
      top: 6px;
      right: 0;
    }

    .bulk-edit-filter {
      padding-bottom: 12px;
      display: flex;
      flex-direction: column;

      + h2 {
        position: relative;
        margin-top: 0;
        padding-top: 24px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -24px;
          width: calc(100% + 48px);
          border-top: 1px solid var(--border);
        }
      }
    }

    .bulk-edit-toggle {
      position: absolute;
      top: 8px;
      right: 0;
    }

    & + .carriage-properties {
      display: none;
    }
  }

  .bulk-edit-controls {
    margin-top: 26px;
    text-align: right;

    .button {
      font-size: 14px;
      line-height: 20px;
      & + .button {
        margin-left: 8px;
      }
    }
  }
}

.properties-edit-panel,
.carriage-properties {

  .menu-toggle {
    font-size: 12px;
    line-height: 16px;
    color: var(--primary);

    .link {
      margin-left: 10px;
    }
  }

  .toggler-wrapper, .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .field {
    .field-label {
      color: var(--text);
      margin-bottom: 0;
    }

    &.sub {
      padding-left: 16px;
      margin-bottom: 16px;
    }
  }

  .toggler-wrapper {
    margin-bottom: 16px;
    height: 20px;
  }
}


.carriage-properties {
  display: flex;
  flex-direction: column;

  .modification-history {
    .process-history-toggle {
      margin-top: 8px;
      margin-bottom: 16px;
      text-align: right;
    }

    .process-list {
      display: none;

      .process {
        margin-bottom: 24px;
      }
    }

    &.open {
      .process-list {
        display: block;
      }

      ~ * {
        display: none;
      }
    }
  }

  .field-label {
    font-weight: bold;
  }

  .input-wrapper {
    .input {
      width: 160px;
      max-width: 100%;
    }

    label {
      width: calc(100% - 160px);
    }
  }
}
