.element-controls-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  &-wrapper {
    &.tippy-box {
      .tippy-content {
        padding: 0 3px;
      }
      .tippy-arrow {
        &:before, &:after {
          transform: scale(1.2, 1.5);
        }
      }

    }
  }

  .rotate-control, .paste-control, .delete-control {
    width: 24px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    cursor: pointer;

    &:hover {
      filter: var(--filter-primary);
    }
  }

  .rotate-control {
    background-image: url('~@fleet/shared/icons/refresh.svg');
  }

  .paste-control {
    background-image: url('~@fleet/shared/icons/clone.svg');
  }

  .delete-control {
    background-image: url('~@fleet/shared/icons/trash.svg');
  }
}