.search-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .table {
    tr {
      td:not(:last-child),
      td:not(:last-child) {
        max-width: 20vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      th {
        width: auto;
      }
      td:last-child {
        padding-left: 16px;
        width: 48px;
        position: relative;
      }
    }
  }

  .loader-overlay + .empty-results {
    display: none;
  }

  .empty-results {
    text-align: center;
    margin-top: 15vh;

    .icon-wrapper {
      display: inline-block;
      width: 86px;
      height: 86px;
      padding: 20px;
      border: 3px solid var(--primary);
      border-radius: 50%;
      cursor: pointer;
    }

    p {
      white-space: pre;
      color: #495057;
    }
  }
}
