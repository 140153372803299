.tabs {
  position: relative;
  z-index: 1;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: -24px;

  .icon {
    cursor: pointer;
    flex-shrink: 0;

    &.close {
      margin-left: 12px;
      margin-right: 16px;
    }

    &.active {
      filter: var(--filter-primary);
    }

    &:hover {
      filter: var(--filter-primary-hover);
    }
  }

  .tab-name {
    text-decoration: none;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 16px;
    max-width: 200px;
    flex-basis: 15%;

    &:hover {
      color: var(--primary-hover);
    }

    &.active {
      color: var(--primary);
    }
  }

  .separator {
    height: 16px;
    border-right: 1px solid  var(--border);
  }

  &.designer-tabs {
    width: calc(100% - 200px);
  }
}
