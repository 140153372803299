.table-wrapper {
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  .table {
    width: 100%;
    border-spacing: 0;

    thead {
      tr {
        background: var(--header);
        line-height: 1.5rem;
      }
    }

    tr {
      cursor: pointer;
      position: relative;

      td:empty:before,
      td > span:empty:before {
        content: '\2014';
      }

      td > span,
      td > a,
      td > span > a {
        display: block;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        vertical-align: middle;
        line-height: 28px;
      }

      td > a,
      td > span > a {
        &.external-link {
          color: var(--primary);
        }

        &:not(.external-link) {
          color: var(--text);
          text-decoration: none;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: inherit;
        }
      }

      &.withSelection {
        td,
        th {
          &:first-child {
            width: 56px;
          }
        }
      }

      &.expandable {
        &:not(.withSelection) {
          td:first-child {
            padding-left: 44px !important;
            background-position: 24px 50%;
            background-repeat: no-repeat;
            background-size: 15px;
            background-image: url('~@fleet/shared/icons/chevron-down.svg');
          }

          &.expanded td:first-child {
            background-image: url('~@fleet/shared/icons/chevron-up.svg');
          }
        }

        &.withSelection {
          td:nth-child(2) {
            padding-left: 20px !important;
            background-position: 0 50%;
            background-repeat: no-repeat;
            background-size: 15px;
            background-image: url('~@fleet/shared/icons/chevron-down.svg');
          }

          &.expanded td:nth-child(2) {
            background-image: url('~@fleet/shared/icons/chevron-up.svg');
          }
        }
      }

      &.expanded-row-info td {
        overflow: visible;
        padding: 0;
      }
    }

    tbody tr:not(.expanded-row-info):hover {
      background: rgba(var(--primary-rgb), 0.1);
    }

    th,
    td {
      text-align: left;
      padding: 12px 8px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }

    th {
      font-weight: 400;
      font-size: 12px;
      padding: 8px;
    }
  }
}
