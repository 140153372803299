.designer {
  &-view {
    background: white;
    flex-grow: 1;
    min-width: 500px;
    text-align: center;
  }

  .fleet-viewer {
    height: 100%;
    .name-controls {
      justify-content: center;
    }
    .canvas {
      padding: 8px;
    }
  }

  .palette-panel, .designer-properties-panel {
    .designer-vehicle-properties {
     h3:first-of-type {
        margin-top: 0;
      }
    }
  }

  .save-control {
    position: absolute;
    right: 0;
    top: -36px;
    color: var(--disabled-gray);
    display: flex;
    align-items: center;

    .success-label, .saving-label {
      margin-left: 10px;
    }
  }

  .designer-properties-panel {
    /**
    * Common
    */
    .seat-filter {
      .collapsible {
        &__header {
          font-weight: bold;
          font-size: 16px;
          color: var(--text);
          margin-bottom: 16px;

          &.active + div .collapsible__content {
            padding-bottom: 16px;
          }
        }

        &__content {
          padding: 8px 0;
        }
      }

      &.visible {

        & + .designer-vehicle-properties,
        & + .designer-selection-properties {
          display: none;
        }
      }
    }

    .scroll-wrapper {
      > h3:first-of-type {
        margin-top: 0;
      }
    }
    .input-wrapper {
      label {
        width: calc(100% - 161px);

        &+div {
          max-width: 161px;
        }
      }
      &.seats-quantity {
        margin-top: 16px;
      }
    }

    .input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .input-wrapper {
        width: 100%;
        margin-bottom: 0;
        padding: 16px 0 0 16px;
      }
    }

    .label-field {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    /**
    * Vehicle properties
    */

    .circle-btn {
      border: 1px solid var(--primary);
      border-radius: 50%;
      display: inline-flex;
      width: 32px;
      height: 32px;
      color: var(--primary);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      margin-left: 12px;

      &.small {
        font-size: 9px;
        margin-left: 16px;
        vertical-align: text-bottom;
        width: 16px;
        height: 16px;
      }
    }

    .header-flex {
      h3 {
        margin: 0;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 16px;

      &.places-number {
        margin-bottom: 28px;
      }
    }

    .standing-places-input {
      label {
        width: calc(100% - 80px);
      }
      .input {
        width: 80px;
      }
    }

    .add-space {
      margin: 16px 0;
    }

    .floor-size {
      h4 {
        display: flex;
        align-items: center;

        &.manageable-space {
          margin: 16px 0;
        }

        .reorder-controls {
          flex-grow: 1;
          text-align: right;

          i {
            cursor: pointer;

            &:hover {
              filter: var(--filter-primary)
            }
          }
        }
      }
    }
  }
}
