* {
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  height: 1px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1180px;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 1px;
  min-height: 100%;

  .app {
    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      &.windows-os .scrollable {
        overflow-y: hidden;
        padding-right: 24px;

        &:hover {
          overflow-y: scroll;
          padding-right: 7px;
        }
      }

      &:not(.windows-os) .scrollable {
        overflow-y: auto;
      }
    }

    &-version {
      padding: 0 24px;
      text-align: right;
      font-size: 12px;
      line-height: 16px;
      color: #8A929F;
    }
  }
}

.panel {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  > h2 {
    margin: 0;
    padding: 16px 24px;
  }
}
